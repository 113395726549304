import React from "react"
import { Helmet } from "react-helmet"
import "../../static/assets/scss/pages/page.scss"
import LayoutDark from "./shared/layout/layout-dark"
import SignUpFormComponent from "./shared/components/sign-up-form.component"
import { navigate } from "gatsby-link"

const routes = require("../types/routes")

export default class WhitePaper extends React.Component {
  constructor(props) {
    super(props)
    this.routes = routes
    this.form = React.createRef()
    this.firstName = React.createRef()
    this.lastName = React.createRef()
    this.email = React.createRef()
    this.stay_up_to_date_with_contingent = React.createRef()
    this.company = React.createRef()
    this.button = React.createRef()
  }

  onButtonStateLoading() {
    this.button.current.classList.add("spinner", "loading")
  }

  onButtonStateDefault() {
    this.button.current.classList.remove("spinner", "disabled", "loading")
  }

  onValidate() {
    const fields = [
      this.firstName.current,
      this.lastName.current,
      this.email.current,
      this.company.current,
    ]
    const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const namePattern = /^[a-zA-Z\s]*$/

    const emailRegex = RegExp(emailPattern)
    const textRegex = RegExp(namePattern)

    let isValid = true
    for (const field of fields) {
      if (field.id === "email") {
        const valid = emailRegex.test(field.value) && field.value.length >= 3
        valid ? this.onMarkAsValid(field) : this.onMarkAsInvalid(field)
        if (valid === false) {
          isValid = false
        }
        const invalidDomains = [
          "gmail.com",
          "hotmail.com",
          "live.com",
          "yahoo.com",
          "icloud.com",
        ]
        const isDomainValid = invalidDomains.every(
          substring => field.value.indexOf(substring) === -1
        )
        if (isDomainValid === false) {
          isValid = false
          this.onMarkAsInvalid(field)
        }
      } else if (field.id === "phone") {
        if (field.value.length > 0) {
          const phonePattern = /^(?=.*[0-9])[- +()0-9]+$/
          const phoneRegex = RegExp(phonePattern)
          const valid = field.value.length >= 7 && phoneRegex.test(field.value)
          valid ? this.onMarkAsValid(field) : this.onMarkAsInvalid(field)
          if (valid === false) {
            isValid = false
          }
        }
      } else {
        if (field.value.length > 0) {
          const valid = textRegex.test(field.value) && field.value.length >= 3
          valid ? this.onMarkAsValid(field) : this.onMarkAsInvalid(field)
          if (valid === false) {
            isValid = false
          }
        }
      }
    }
    return isValid
  }

  onMarkAsInvalid(field) {
    field.parentNode.classList.add("error")
    this.button.current.classList.add("disabled")
  }

  onMarkAsValid(field) {
    field.parentNode.classList.remove("error")
    this.button.current.classList.remove("disabled")
  }

  onEnterKeypress(e) {
    this.onMarkAsValid(e.target)
    if (e.key === "Enter") {
      e.preventDefault()
      this.onSubmitForm(e)
    }
  }

  onGetCookies(string) {
    return Object.fromEntries(
      document.cookie.split("; ").map(v => v.split("=").map(decodeURIComponent))
    )[string]
  }

  onSubmitForm(e) {
    this.onButtonStateLoading()
    if (this.onValidate()) {
      const formData = new FormData(this.form.current)
      let checkboxValue = false
      if (formData.get("stay_up_to_date_with_contingent") === "on") {
        checkboxValue = true
      }
      const hutk = this.onGetCookies("hubspotutk")
      const data = {
        fields: [
          { name: "firstname", value: formData.get("firstname") },
          { name: "lastname", value: formData.get("lastname") },
          { name: "email", value: formData.get("email") },
          { name: "company", value: formData.get("company") },
          { name: "stay_up_to_date_with_contingent", value: checkboxValue },
        ],
        context: {
          hutk: hutk,
          pageUri: window.location.href,
        },
      }
      this.ajax(
        this.form.current.method,
        this.form.current.action,
        data,
        data => {
          this.onButtonStateDefault()
          navigate(
            `${this.routes.routes.whitePaperSuccess}?email=${this.email.current.value}`,
            {}
          )
        },
        (error, data) => {
          const resp = JSON.parse(data)
          const message = resp.errors[0].message
          this.onButtonStateDefault()
          this.onMarkAsInvalid(message)
        }
      )
    } else {
      setTimeout(() => {
        this.onButtonStateDefault()
      }, 500)
    }
  }

  ajax(method, url, data, success, error) {
    var xhr = new XMLHttpRequest()
    xhr.open(method, url)
    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.onreadystatechange = function() {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        success(xhr.response, xhr.responseType)
      } else {
        error(xhr.status, xhr.response, xhr.responseType)
      }
    }
    xhr.send(JSON.stringify(data))
  }

  render() {
    return (
      <LayoutDark>
        <Helmet>
          <title>Contingent: 2021 Compliance Checklist</title>
          <meta name="title" content="Contingent: 2021 Compliance Checklist" />
          <link
            rel="canonical"
            href={`${process.env.BASE_URL}/2021-compliance-checklist/`}
          />
          <meta
            name="description"
            content="Governments and trading blocs are taking an ever-more global approach to compliance. For the upcoming year, global regulators are homing in on operational resilience, including cyber security, fraud prevention and sustainability."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`${process.env.BASE_URL}/2021-compliance-checklist/`}
          />
          <meta
            property="og:title"
            content="Contingent: 2021 Compliance Checklist"
          />
          <meta
            property="og:description"
            content="Governments and trading blocs are taking an ever-more global approach to compliance. For the upcoming year, global regulators are homing in on operational resilience, including cyber security, fraud prevention and sustainability."
          />
          <meta
            property="og:image"
            content={`${process.env.BASE_URL}/assets/images/web/whitepaper-image.png`}
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`${process.env.BASE_URL}/2021-compliance-checklist/`}
          />
          <meta
            property="twitter:title"
            content="Contingent: 2021 Compliance Checklist"
          />
          <meta
            property="twitter:description"
            content="Governments and trading blocs are taking an ever-more global approach to compliance. For the upcoming year, global regulators are homing in on operational resilience, including cyber security, fraud prevention and sustainability."
          />
          <meta
            property="twitter:image"
            content={`${process.env.BASE_URL}/assets/images/web/whitepaper-image.png`}
          />
        </Helmet>
        <div className={"section-container"}>
          <div className={"section-info basic white-paper"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column centered"}>
                  <div className={"content"}>
                    <p className={"tag"}>Resources</p>
                    <h1 className={"title"}>
                      2021 compliance checklist for procurement professionals{" "}
                    </h1>
                    <p className={"description"}>
                      Governments and trading blocs are taking an ever-more
                      global approach to compliance. For the upcoming year,
                      global regulators are homing in on operational resilience,
                      including cyber security, fraud prevention and
                      sustainability.
                    </p>
                  </div>
                </div>
              </div>
              <div className={"white-paper-content"}>
                <div className={"card"}>
                  <div className={"header"}>
                    <div className={"banner"}>
                      <div className={"banner-texture"}></div>
                    </div>
                  </div>
                  <div className={"groups"}>
                    <div className={"group"}>
                      <h5 className={"title bold-font"}>
                        Download the full checklist
                      </h5>
                      <p className={"description"}>
                        We’ve put together the major compliance updates you need
                        to be ready for in 2021.
                      </p>
                      <div className={"checklist"}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.867"
                          height="17.867"
                          viewBox="0 0 17.867 17.867"
                        >
                          <g
                            id="Group_3253"
                            data-name="Group 3253"
                            transform="translate(-172.274 -694.499)"
                          >
                            <circle
                              id="Ellipse_113"
                              data-name="Ellipse 113"
                              cx="8.934"
                              cy="8.934"
                              r="8.934"
                              transform="translate(172.274 694.499)"
                              fill="#1abc9c"
                              opacity="0.113"
                            />
                            <path
                              id="checkmark"
                              d="M3.694,42.079,0,38.676l1.285-1.4L3.5,39.319l4.365-5.335,1.469,1.2Z"
                              transform="translate(176.726 665.765)"
                              fill="#1abc9c"
                            />
                          </g>
                        </svg>
                        What should your compliance checklist include
                      </div>
                      <div className={"checklist"}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.867"
                          height="17.867"
                          viewBox="0 0 17.867 17.867"
                        >
                          <g
                            id="Group_3253"
                            data-name="Group 3253"
                            transform="translate(-172.274 -694.499)"
                          >
                            <circle
                              id="Ellipse_113"
                              data-name="Ellipse 113"
                              cx="8.934"
                              cy="8.934"
                              r="8.934"
                              transform="translate(172.274 694.499)"
                              fill="#1abc9c"
                              opacity="0.113"
                            />
                            <path
                              id="checkmark"
                              d="M3.694,42.079,0,38.676l1.285-1.4L3.5,39.319l4.365-5.335,1.469,1.2Z"
                              transform="translate(176.726 665.765)"
                              fill="#1abc9c"
                            />
                          </g>
                        </svg>
                        Why you need to look outside your own industry
                      </div>
                      <div className={"checklist"}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.867"
                          height="17.867"
                          viewBox="0 0 17.867 17.867"
                        >
                          <g
                            id="Group_3253"
                            data-name="Group 3253"
                            transform="translate(-172.274 -694.499)"
                          >
                            <circle
                              id="Ellipse_113"
                              data-name="Ellipse 113"
                              cx="8.934"
                              cy="8.934"
                              r="8.934"
                              transform="translate(172.274 694.499)"
                              fill="#1abc9c"
                              opacity="0.113"
                            />
                            <path
                              id="checkmark"
                              d="M3.694,42.079,0,38.676l1.285-1.4L3.5,39.319l4.365-5.335,1.469,1.2Z"
                              transform="translate(176.726 665.765)"
                              fill="#1abc9c"
                            />
                          </g>
                        </svg>
                        Compliance updates covering FCA, EU Regulations, IMO
                        Cyber Security and more
                      </div>
                      <div className={"checklist"}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.867"
                          height="17.867"
                          viewBox="0 0 17.867 17.867"
                        >
                          <g
                            id="Group_3253"
                            data-name="Group 3253"
                            transform="translate(-172.274 -694.499)"
                          >
                            <circle
                              id="Ellipse_113"
                              data-name="Ellipse 113"
                              cx="8.934"
                              cy="8.934"
                              r="8.934"
                              transform="translate(172.274 694.499)"
                              fill="#1abc9c"
                              opacity="0.113"
                            />
                            <path
                              id="checkmark"
                              d="M3.694,42.079,0,38.676l1.285-1.4L3.5,39.319l4.365-5.335,1.469,1.2Z"
                              transform="translate(176.726 665.765)"
                              fill="#1abc9c"
                            />
                          </g>
                        </svg>
                        The benefits of proactive third party monitoring
                      </div>
                    </div>
                    <div className={"group"}>
                      <form
                        className={"form sub-form"}
                        ref={this.form}
                        method={"post"}
                        action={
                          "https://api.hsforms.com/submissions/v3/integration/submit/8614381/df67bfa3-4d6a-4247-9a1f-3ea2e96dc94c"
                        }
                      >
                        <div className={"body"}>
                          <div className={"field-container"}>
                            <div className={"label"}>
                              <label className={"label-text"}>First name</label>
                              <p className={"description"}>Optional</p>
                            </div>
                            <div className={"field"}>
                              <input
                                ref={this.firstName}
                                onKeyDown={e => this.onEnterKeypress(e)}
                                name="firstname"
                                type={"text"}
                                placeholder={"e.g Tony"}
                              />
                              <div className={"error-text"}>
                                Please provide a valid first name
                              </div>
                            </div>
                          </div>
                          <div className={"field-container"}>
                            <div className={"label"}>
                              <label className={"label-text"}>Last name</label>
                              <p className={"description"}>Optional</p>
                            </div>
                            <div className={"field"}>
                              <input
                                ref={this.lastName}
                                onKeyDown={e => this.onEnterKeypress(e)}
                                name="lastname"
                                type={"text"}
                                placeholder={"e.g Stark"}
                              />
                              <div className={"error-text"}>
                                Please provide a valid last name
                              </div>
                            </div>
                          </div>
                          <div className={"field-container"}>
                            <div className={"label"}>
                              <label className={"label-text"}>Email</label>
                              <p className={"description"}>Required</p>
                            </div>
                            <div className={"field"}>
                              <input
                                ref={this.email}
                                onKeyDown={e => this.onEnterKeypress(e)}
                                name="email"
                                type={"text"}
                                id="email"
                                placeholder={"e.g tony@starkenterprise.com"}
                              />
                              <div className={"error-text"}>
                                Please provide a valid work email
                              </div>
                            </div>
                          </div>
                          <div className={"field-container"}>
                            <div className={"label"}>
                              <label className={"label-text"}>Company</label>
                              <p className={"description"}>Optional</p>
                            </div>
                            <div className={"field"}>
                              <input
                                ref={this.company}
                                onKeyDown={e => this.onEnterKeypress(e)}
                                name="company"
                                type={"text"}
                                placeholder={"e.g Stark Enterprises"}
                              />
                              <div className={"error-text"}>
                                Provide valid company name
                              </div>
                              <div className={"tick"}>
                                <p className={"description"}>
                                  Stay up to date with Contingent
                                </p>
                                <input
                                  ref={this.stay_up_to_date_with_contingent}
                                  type="checkbox"
                                  defaultChecked={true}
                                  className={"checkbox"}
                                  name={"stay_up_to_date_with_contingent"}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={"field-container button"}>
                            <button
                              type={"button"}
                              ref={this.button}
                              onClick={e => this.onSubmitForm(e)}
                            >
                              SEND ME THE CHECKLIST
                              <div className={"spinner"}></div>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className={"content"}>
                  <div className={"group"}>
                    <h2 className={"title"}>About Contingent AI</h2>
                    <p className={"description"}>
                      Contingent is a global provider of insights and analytics
                      for procurement and supply chain teams across industries,
                      enabling them to make better decisions, get better results
                      and be more productive.
                      <br /> <br />
                      Get in touch to see how can our AI-powered platform help
                      to build better resilience for you and your team.
                    </p>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                  <div className={"group"}>
                    <div className={"logo-text"}>
                      Trusted by organizations of all shapes and sizes
                    </div>
                    <div className={"logos"}>
                      <img src={"/assets/images/logos/ukgovblack.svg"} />
                      <img src={"/assets/images/logos/monzo-black.svg"} />
                    </div>
                    <div className={"logos"}>
                      <img src={"/assets/images/logos/seagate-black.svg"} />
                      <img src={"/assets/images/logos/vyne.svg"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Move towards proactive third-party risk management and
                    monitoring today.
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}
